<template>
  <div class="down-center">

    <div class="title-container">
      <div class="title">下载中心</div>
    </div>

    <div class="down-container">

      <div class="search">
        <el-input prefix-icon="el-icon-search"  v-model="keyword" placeholder="请输入文件名称搜索"/>
        <el-button type="primary" @click="searchData">查询</el-button>
      </div>

      <el-table v-loading="loading" :data="tableData" header-cell-class-name="table-header-cell"
                cell-class-name="table-cell">
        <el-table-column label="项目名称" prop="name"></el-table-column>
        <el-table-column label="时间" prop="createAt" width="200" align="center"></el-table-column>
        <el-table-column label="类型" prop="suffix" width="200" align="center"></el-table-column>
        <el-table-column label="大小" prop="size" width="100" align="center">
          <template slot-scope="{row}">
            <span>{{ computedFileSize(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="{row}">
            <el-button v-if="row.downloadStatus === 1" style="color: #309AF2;cursor: default" type="text">数据准备中</el-button>
            <el-button v-if="row.downloadStatus === 2" style="color: #309AF2" type="text" @click="downFile(row)">下载
            </el-button>
            <el-button v-if="row.downloadStatus === 3" style="color: #999999;cursor: default" type="text">已过期</el-button>
            <el-button v-if="row.downloadStatus === 4" style="color: #999999;cursor: default" type="text">数据准备失败</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pageIndex"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      keyword: '',
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  computed: {
    computedFileSize() {
      return (row) => {
        let size = row.size
        let finalSize;
        const KB = 1024
        const MB = KB * 1024
        const GB = MB * 1024
        if (size > GB) {
          finalSize = (size / GB).toFixed(1) + 'GB'
        } else if (size > MB) {
          finalSize = (size / MB).toFixed(1) + 'MB'
        } else {
          finalSize = (size / KB).toFixed(1) + 'KB'
        }
        return finalSize
      }
    }
  },
  mounted() {
    this.fetchDownList('')
  },
  methods: {
    fetchDownList(val) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.downLoadCenterList,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          userId: sessionStorage.getItem('teacherId'),
          name: val,
          page: this.pageIndex,
          pageSize: this.pageSize
        }
      })
      .then(res => {
        this.loading = false
        if (res.state === '11') {
          this.tableData = res?.data?.pageContents
          this.totalCount = res?.data?.total
          this.pageIndex = res?.data?.page
          this.pageSize = res?.data?.pageSize
        }
      })
    },
    handleCurrentChange(val) {
      console.log('val', val)
      this.pageIndex = val
      this.fetchDownList(this.keyword)
    },
    searchData() {
      this.fetchDownList(this.keyword)
    },
    downFile(row) {
      const downloadElement = document.createElement('a')
      downloadElement.href = row.downloadUrl
      downloadElement.download = `${row.name}成绩统计表.xls`
      document.body.appendChild(downloadElement)
      downloadElement.click()
    },
  }
}
</script>

<style lang="scss" scoped>

.title-container {
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    padding: 28px 0 28px 28px;
  }
}

.down-container {
  background-color: #FFFFFF;
  height: calc(100vh - 84px - 83px);

  .el-table {
    padding: 0 28px;

    ::v-deep .table-header-cell {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      height: 54px;
      background: #F6F6F6;
    }

    ::v-deep .table-cell {
      .cell {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    ::v-deep .el-table__body tr:hover > td {
      background-color: #F6F6F6 !important;
    }

  }


  .search {
    padding: 20px 0 20px 28px;

    .el-input {
      width: 350px;
      height: 36px;
      background: #F6F6F6;
    }

    .el-button {
      width: 100px;
      height: 36px;
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
      border-radius: 4px;
      margin-left: 14px;
    }
  }
  .pagination{
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
}


</style>
